// 成绩库管理
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";
// 分页查询
export const getCjk = params => {
  return getRequest("/yethan/examBankRecord/listPage", params);
};
// 添加
export const addCjk = params => {
  return postRequest("/yethan/examBankRecord", params);
};
// 修改
export const editCjk = params => {
  return putRequest("/yethan/examBankRecord", params);
};
// 删除数据
export function deleteCjk(sid) {
  return deleteRequest("/yethan/examBankRecord/" + sid);
}
// 或取成绩库详情
export function getCjkInfo(sid) {
  return getRequest("/yethan/examBankRecord/getInfo/" + sid);
}
// 清空成绩库
export function clearCjk(cjkid) {
  return deleteRequest("/yethan/examBank/clearBank?cjkid=" + cjkid);
}

// 解压照片
export function decompCjkImg(fileId, cjkid) {
  return postRequest(`/yethan/examBankRecord/unzipExamBankPhoto/${fileId}/${cjkid}`);
}
// 清空时间设置
export function clearCjkTime(sid, field) {
  return putRequest(`/yethan/examBankRecord/updateCjkTime/${sid}/${field}`);
}
