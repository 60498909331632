// 成绩单样式模板
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";

// 成绩单样式保存模板
export const saveScorTemplate = function(params) {
  return postRequest("/yethan/print/printTemplate/save", params)
}

// 模板样式详情
export function getTemplateInfo(mid) {
  return getRequest("/yethan/print/printTemplate/" + mid);
}
// 模板修改
export const editTemplate = params => {
  return putRequest("/yethan/print/printTemplate/update", params);
};
// 模板下拉
export const TemplateEasyList = params => {
  return getRequest("/yethan/print/printTemplate/easyList", params);
};

// 删除
export function deleteModule(mid) {
  return deleteRequest("/yethan/print/printTemplate/" + mid);
}
