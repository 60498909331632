<style type="text/css">
  @import "~@/assets/css/table.css";
</style>
<script>
  export default {
    components: {},
    props: {
      title: {
        type: String,
        default: "",
      },
      title2: {
        type: String,
        default: "",
      },
      subTitle: {
        type: String,
        default: "",
      },
      subTitle2: {
        type: String,
        default: "",
      },
      items: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    methods: {
      addItem() {
        this.$emit("addItem")
      }
    },
    computed: {
      newitems() {
        return this.$store.state.layout.items;
      },
    },
  };
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center">
        <div class="page-title-right">
          <b-breadcrumb :items="newitems" class="m-0"></b-breadcrumb>
        </div>
        <h4 class="mb-0 ml-3">
          {{ title2
          }}<span class="font-blue ml-2" v-b-modal.showmodal>{{
            subTitle
          }}</span>
          <span class="font-blue ml-2" @click="addItem()" style="cursor: pointer;">{{subTitle2}}</span>
        </h4>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
